@import '/src/style-definitions.scss';

@font-face {font-family: "Athelas W01 Regular";
  font-weight: normal;
  font-style: normal;
  src: url("assets/Font/Athelas/Regular/8a8da4e29aedaa26c72e1b57293ddf2d.eot"); /* IE9*/
  src: url("assets/Font/Athelas/Regular/8a8da4e29aedaa26c72e1b57293ddf2d.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("assets/Font/Athelas/Regular/8a8da4e29aedaa26c72e1b57293ddf2d.woff2") format("woff2"), /* chrome、firefox */
  url("assets/Font/Athelas/Regular/8a8da4e29aedaa26c72e1b57293ddf2d.woff") format("woff"), /* chrome、firefox */
  url("assets/Font/Athelas/Regular/8a8da4e29aedaa26c72e1b57293ddf2d.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("assets/Font/Athelas/Regular/8a8da4e29aedaa26c72e1b57293ddf2d.svg#Athelas W01 Regular") format("svg"); /* iOS 4.1- */
}

@font-face {font-family: "Athelas W01 Italic";
  font-weight: normal;
  font-style: normal;
  src: url("assets/Font/Athelas/Italic/8da1eda87a62a1c45f8c770ff9ffd90f.eot"); /* IE9*/
  src: url("assets/Font/Athelas/Italic/8da1eda87a62a1c45f8c770ff9ffd90f.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("assets/Font/Athelas/Italic/8da1eda87a62a1c45f8c770ff9ffd90f.woff2") format("woff2"), /* chrome、firefox */
  url("assets/Font/Athelas/Italic/8da1eda87a62a1c45f8c770ff9ffd90f.woff") format("woff"), /* chrome、firefox */
  url("assets/Font/Athelas/Italic/8da1eda87a62a1c45f8c770ff9ffd90f.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("assets/Font/Athelas/Italic/8da1eda87a62a1c45f8c770ff9ffd90f.svg#Athelas W01 Italic") format("svg"); /* iOS 4.1- */
}

@font-face {font-family: "Athelas W01 Bold";
  font-style: normal;
  font-weight: bold;
  src: url("assets/Font/Athelas/Bold/e545b409a314dd68d78905e0ebe9ab8e.eot"); /* IE9*/
  src: url("assets/Font/Athelas/Bold/e545b409a314dd68d78905e0ebe9ab8e.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("assets/Font/Athelas/Bold/e545b409a314dd68d78905e0ebe9ab8e.woff2") format("woff2"), /* chrome、firefox */
  url("assets/Font/Athelas/Bold/e545b409a314dd68d78905e0ebe9ab8e.woff") format("woff"), /* chrome、firefox */
  url("assets/Font/Athelas/Bold/e545b409a314dd68d78905e0ebe9ab8e.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("assets/Font/Athelas/Bold/e545b409a314dd68d78905e0ebe9ab8e.svg#Athelas W01 Bold") format("svg"); /* iOS 4.1- */
}

@font-face {
  font-family: 'EdwardianScriptITCW01';
  src: url('assets/Font/Fonts/731296/d99f0ff7-9f4b-4d62-9283-7de76d63580e.woff2') format("woff2"),
    url("assets/Font/Fonts/731296/e92a529b-97f5-440f-b8a4-a2ad31c621c4.woff") format("woff");
}

#shell-overlay-container {
  // overflow: hidden!important;
  height: 100% !important;
}

.tippy-box {
  font-size: 2vh !important;
  padding: 0.3rem 0.6rem;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  height: 100%;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  margin: 0;
  overflow-x: hidden;
  background-color: $color-base;

  font-family: 'Athelas W01 Regular';
}

h1 {
  font-size: $header-text-size;
  color: $color-white;
  text-transform: uppercase;

  font-weight: normal;
  font-family: "Athelas W01 Italic";
}

h2 {
  font-size: $header2-text-size;
  color: $primary-text;
  text-transform: uppercase;

  font-weight: normal;
  font-family: "Athelas W01 Italic";
}

button {
  background-color: $primary-text;
  text-transform: uppercase;
}

svg,
g,
path {
  overflow: hidden;
  pointer-events: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

img {
  pointer-events: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

img,
div,
a {
  // -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
